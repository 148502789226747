let burger = document.querySelector('.js-burger');
let menu   = document.querySelector('.js-mobile-menu__list');

burger.onclick = function (){
	menu.classList.toggle('is-active');
}
window.onscroll = function() {myFunction()};

let header = document.querySelector(".js-header__right");
let wrap = document.querySelector(".js-header__right-wrapper");
let sticky = header.offsetTop;
	

function myFunction() {
	if(window.matchMedia("(max-width: 768px)").matches){
		if (window.pageYOffset > sticky) {
			header.classList.add("is-sticky");
			wrap.classList.add("is-active");
		} else {
			header.classList.remove("is-sticky");
			wrap.classList.remove("is-active");
		}
	}
}
